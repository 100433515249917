.tech-icon{
    font-size: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: #0e314c;
}

.client-icon{
    margin-left: 6rem;
    margin-right: 6rem;
}